import { useEffect, useState } from 'react'
import './App.css'
import { onAuthStateChanged } from 'firebase/auth'
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { getCurrentUser, logout, auth } from './util/auth'
import LoginComponent from './components/LoginComponent'
import ClassicMode from './screens/ClassicMode'
import { getUserData, claimDaily } from './util/api'
import { to2Decimals } from './util/logic'
import Button from './components/CustomButton'
import Colours from './constants/Colours'
// import UserScreen from './screens/UserScreen'
// import Leaderboard from './screens/Leaderboard'

function App() {
	const [loadingAuth, setLoadingAuth] = useState(true)
	const [currentUser, setCurrentUser] = useState(null)
	const [userData, setUserData] = useState(null)
	const [loadingClaim, setLoadingClaim] = useState(false)

	useEffect(() => {
		if (currentUser && !userData) {
			console.log('Gettings user data')
			getUserData().then((data) => {
				setUserData(data)
			})
		}
	}, [currentUser, userData])

	onAuthStateChanged(auth, (user) => {
		if (user) {
			getCurrentUser().then((data) => {
				setCurrentUser(data)
				setLoadingAuth(false)
			})
		} else {
			setCurrentUser(null)
			setLoadingAuth(false)
		}
	})

	const handleClaimDaily = async (e) => {
		e.preventDefault()
		console.log('hiuy')
		if (loadingClaim) return

		// setLoadingClaim(true)
		await claimDaily().then((data) => {
			if (data) setUserData({ ...userData, ...data })
		})
		// setLoadingClaim(false)
	}

	if (loadingAuth) return <div className="splash-screen">JKSJ</div>

	return (
		<div className="App">
			{/* <Router> */}
				<header className="App-header">
					{/* <span class="material-symbols-outlined" onClick={() => window.location = ('/user')}>
						person
					</span> */}
					<p>Casino</p>
					{/* <span class="material-symbols-outlined" onClick={() => window.location = ('/leaderboard')}>
						leaderboard
					</span> */}
				</header>

				<div className="App-body">
					{currentUser ? (
						<>
							<p>Balance: ${userData ? to2Decimals(userData.balance) : '--'}</p>
							<ClassicMode userData={userData} setUserData={setUserData} />
							{/* <Routes>
								<Route path="/" element={<ClassicMode userData={userData} setUserData={setUserData} />} />
								<Route path="/leaderboard" element={<Leaderboard />} />
								<Route path="/user" element={<UserScreen />} />
							</Routes> */}
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-evenly',
									flexDirection: 'row',
									width: '90%',
								}}
							>
								<Button
									onClick={handleClaimDaily}
									colour={Colours.blue}
									textColour={Colours.foreground}
									disabled={loadingClaim}
								>
									Claim Daily $100
								</Button>
								<Button onClick={logout} colour={Colours.red} textColour={Colours.foreground}>
									Logout
								</Button>
							</div>
						</>
					) : (
						<LoginComponent setUserData={setUserData} />
					)}
				</div>
			{/* </Router> */}
		</div>
	)
}

export default App
