import React from 'react'
import Colours from '../constants/Colours'

const Link = ({ text, onClick, fontSize }) => {
	return (
		<span onClick={onClick} style={{ color: Colours.blue, textDecorationLine: 'underline', cursor: 'pointer', fontSize: fontSize || 16 }}>
			{text}
		</span>
	)
}

export default Link
