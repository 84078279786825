import { useState } from 'react'
import '../App.css'

import { loginWithEmail, registerWithEmail, resetPassword } from '../util/auth'
import Button from './CustomButton'
import Colours from '../constants/Colours'
import Input from './CustomInput'
import Link from './CustomLink'

function LoginComponent({ setUserData }) {
	const [resetPasswordMode, setResetPasswordMode] = useState(false)
	const [loginMode, setLoginMode] = useState(true)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [displayName, setDisplayName] = useState('')

	const [loading, setLoading] = useState(false)

	if (resetPasswordMode) {
		return (
			<div>
				<p>Reset Password</p>
				<form
					style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
					onSubmit={(e) => {
						e.preventDefault()
						resetPassword(email).then(() => {
							setResetPasswordMode(false)
						})
					}}
				>
					<Input type="email" placeholder="Email" setValue={setEmail} style={{ width: '100%' }} />
					<div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', justifyContent: 'space-between' }}>
						<Button type="submit" colour={Colours.green} textColour={Colours.foreground} sm>
							Submit
						</Button>
						<Button onClick={() => setResetPasswordMode(false)} sm>
							Cancel
						</Button>
					</div>
				</form>
			</div>
		)
	}

	return (
		<div>
			<p>{loginMode ? 'Login' : 'Create an Account'}</p>

			<form
				style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
				onSubmit={(e) => {
					e.preventDefault()
					setLoading(true)
					if (loginMode) {
						loginWithEmail(email, password).then((result) => {
							setLoading(false)
							setUserData(result)
						})
					} else {
						if (password !== confirmPassword) {
							alert("Passwords don't match")
							setLoading(false)
							return
						}
						registerWithEmail(email, password, displayName).then((result) => {
							setLoading(false)
							setUserData(result)
						})
					}
				}}
			>
				<span style={{ fontSize: 16, width: '100%', textAlign: 'left', margin: '5px' }}>
					{loginMode ? "Don't have an account? " : 'Already have an account? '}
					<Link onClick={() => setLoginMode(!loginMode)} text={loginMode ? 'Register' : 'Login'} />
				</span>
				<Input type="email" placeholder="Email" setValue={setEmail} style={{ width: '100%' }} />
				{!loginMode && <Input type="text" placeholder="Display Name" setValue={setDisplayName} style={{ width: '100%' }} />}
				<Input type="password" placeholder="Password" setValue={setPassword} style={{ width: '100%' }} />
				{!loginMode && <Input type="password" placeholder="Confirm Password" setValue={setConfirmPassword} style={{ width: '100%' }} />}
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
					{loginMode ? <Link onClick={() => setResetPasswordMode(true)} text="Forgot your password?" /> : <div />}
					<Button type="submit" colour={Colours.green} textColour={Colours.foreground} sm>
						{loginMode ? 'Login' : 'Create Account'}
					</Button>
				</div>
			</form>

			{loading && <p>Loading...</p>}
		</div>
	)
}

export default LoginComponent
