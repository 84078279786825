import React from 'react'
import Colours from '../constants/Colours'

const Input = ({ id, setValue, type, placeholder, onChange, step, inputMode, lg, sm, style }) => {
	return (
		<input
			id={id}
			type={type || "text"}
			placeholder={placeholder}
            step={step}
			onChange={(e) => {
				setValue ? setValue(e.target.value) : onChange(e)
			}}
			style={{
				backgroundColor: Colours.background,
				color: Colours.foreground,
				borderColor: Colours.foreground,
				borderStyle: 'solid',
				borderRadius: '5px',
				outlineColor: Colours.blue,
                marginBottom: '5px',
				padding: '5px',
                fontSize: lg? 'calc(12px + 2vmin)': sm ? 'calc(4px + 2vmin)': 'calc(10px + 2vmin)',
                ...style
			}}
            inputMode={inputMode}
			required
		/>
	)
}

export default Input
