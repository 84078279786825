import { initializeApp } from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth'
import { createUser } from './api'

const firebaseConfig = {
	apiKey: 'AIzaSyBMmsblTFvksw7CRdJs5CeAlgmAzIFzg7U',
	authDomain: 'jksj-67434.firebaseapp.com',
	projectId: 'jksj-67434',
	storageBucket: 'jksj-67434.appspot.com',
	messagingSenderId: '1007512300586',
	appId: '1:1007512300586:web:225bd4386bac29152fe753',
	measurementId: 'G-9CMMJ6FMKH',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

export const loginWithEmail = async (email, password) => {
	await signInWithEmailAndPassword(auth, email, password).catch((error) => {
		let errorMessage = error.message
		if(errorMessage === 'Firebase: Error (auth/wrong-password).' || errorMessage === 'Firebase: Error (auth/user-not-found).') {
			errorMessage = 'Invalid email or password'
		}
		alert(errorMessage)
	})
}

export const registerWithEmail = async (email, password, displayName) => {
	await createUserWithEmailAndPassword(auth, email, password)
		.then(async (userCredential) => {
			return await createUser(displayName)
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			console.log(errorCode, errorMessage)
			alert(errorMessage)
		})
}

export const logout = () => {
	signOut(auth).catch((error) => {
		const errorCode = error.code
		const errorMessage = error.message
		console.log(errorCode, errorMessage)
		alert(errorMessage)
	})
}

export const resetPassword = async (email) => {
	await sendPasswordResetEmail(auth, email)
		.then(() => {
			alert('Password reset email sent!')
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			if (errorCode === 'auth/user-not-found') {
				alert('No user found with that email address')
			} else {
				alert(errorMessage)
			}
		})
}

export const isAuthed = () => {
	console.log('auth.currentUser: ' + JSON.stringify(auth.currentUser))
	return !!auth.currentUser
}

export const getCurrentUser = async () => {
	try {
		const user = auth.currentUser
		return user
	} catch (e) {
		console.log('Error getting user data: ' + e)
		return null
	}
}
