import { useState } from 'react'
import '../App.css'
import { placeBet } from '../util/api'
import { to2Decimals } from '../util/logic'
import Colours from '../constants/Colours'
import Button from '../components/CustomButton'
import Input from '../components/CustomInput'

function ClassicMode({ userData, setUserData }) {
	const [loading, setLoading] = useState(false)
	const [potentialReturns, setPotentialReturns] = useState(0)

	const startResultAnimation = (data) => {
		const resultElement = document.getElementById('oddsText')
		resultElement.style.backgroundColor = data.result ? Colours.green : Colours.red
		setTimeout(() => {
			resultElement.style.backgroundColor = Colours.foreground
			setUserData({ ...userData, balance: data.balance, currentOdds: data.currentOdds })
			calculatePotentialReturns(data.currentOdds)
			setLoading(false)
		}, 700)
	}

	const submitBet = async (e) => {
		e.preventDefault()
		if (loading) return

		const betAmountField = document.getElementById('betAmount')
		const betAmount = betAmountField.value
		setLoading(true)
		await placeBet(betAmount)
			.then((data) => {
				if (data.error) {
					alert(data.error)
					setLoading(false)
					return
				}
				startResultAnimation(data)
				betAmountField.value = ''
			})
			.catch((err) => {
				console.log(err)
				setLoading(false)
			})
	}

	const calculatePotentialReturns = (newOdds = userData.currentOdds) => {
		// e.preventDefault()
		const betAmount = document.getElementById('betAmount').value
		const potentialReturns = betAmount * (100 / newOdds) - betAmount
		setPotentialReturns(to2Decimals(potentialReturns))
	}

	return (
		<div>
			<span id="oddsText" className="Odds-text">
				{!userData ? '--' : userData.currentOdds}%
			</span>
			<br />
			<br />
			<div>
				<form id="betForm" onSubmit={submitBet} className="Bet-input-form">
					<Input
						id="betAmount"
						placeholder="Bet Amount"
						onChange={() => calculatePotentialReturns()}
						type="number"
						step=".01"
						inputMode="numeric"
						lg
						style={{
							borderTopRightRadius: '0px',
							borderBottomRightRadius: '0px',
							display: 'inline-block',
							border: '3px solid',
							borderColor: Colours.foreground,
						}}
					/>
					<Button
						type="submit"
						color={Colours.foreground}
						lg
						style={{
							borderTopLeftRadius: '0px',
							borderBottomLeftRadius: '0px',
							display: 'inline-block',
							border: '3px solid',
							borderColor: Colours.foreground,
						}}
						disabled={loading}
					>
						Submit
					</Button>
				</form>
				<p id="potentialReturns" style={{ flex: 1, width: '100%', textAlign: 'left', fontSize: 'calc(6px + 2vmin)', marginTop: '0px' }}>
					Potential Returns: ${!userData ? '--' : potentialReturns}
				</p>
			</div>
		</div>
	)
}

export default ClassicMode
