import React from 'react'
import Colours from '../constants/Colours'

const Button = ({ border, colour, textColour, children, height, onClick, width, disabled, lg, sm, style }) => {
	return (
		<button
			onClick={onClick}
			style={{
				backgroundColor: disabled ? Colours.grey : colour || Colours.foreground,
				color: disabled ? Colours.background : textColour || Colours.background,
				border: disabled ? 'none' : border || 'none',
				borderRadius: '5px',
				height,
				width,
				marginBottom: '5px',
				padding: '5px',
                fontSize: lg? 'calc(12px + 2vmin)': sm ? 'calc(4px + 2vmin)': 'calc(10px + 2vmin)',
                ...style
			}}
            type
			disabled={disabled}
		>
			{children}
		</button>
	)
}

export default Button
