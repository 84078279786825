import { auth } from './auth'

const authenticatedPostRequest = async (url, body) => {
	const token = await auth.currentUser.getIdToken()
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
		},
		body: JSON.stringify(body),
	})
	const data = await response.json()
	return data
}

const authenticatedGetRequest = async (url) => {
	const token = await auth.currentUser.getIdToken()
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
		},
	})
	const data = await response.json()
	return data
}

export const placeBet = async (betAmount) => {
	const data = await authenticatedPostRequest('/bet', { betAmount })
	return data
}

export const createUser = async (displayName) => {
	const date = new Date()
	const utcOffset = date.getTimezoneOffset()
	const data = await authenticatedPostRequest('/createUser', { displayName, utcOffset })
	return data
}

export const claimDaily = async () => {
	const data = await authenticatedPostRequest('/claimDaily', {})
	if (data.error) {
		alert(data.error)
		return null
	}
	return data
}

export const getUserData = async (userId = null) => {
	try {
		if (userId) {
			const userData = await authenticatedGetRequest('/api/user/' + userId)
			return userData
		}
		const user = auth.currentUser
		if (user) {
			const userData = await authenticatedGetRequest('/api/user/' + user.uid)
			return userData
		}
		console.error('No user')
		return null
	} catch (e) {
		console.log('Error getting user data: ' + e)
		return null
	}
}
